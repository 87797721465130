//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";

import axios from "axios";
import Popup from '../../../components/Popup.vue';
import Formatter from "../../../components/libraries/Formatter";

const formatter = new Formatter();

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue
} from "vuelidate/lib/validators";


export default {
  components: {
    Layout,
    PageHeader,
    Popup,
    DatePicker
},
  data() {
    return {
      submitted: false,

      groups: [],
      selectedGroup: 0,

      promoCodes: [],

      addGroupPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deleteGroupPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      newGroup: {
        codename: '',
        description: ''
      },

      addPromocodePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletePromocodePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedPromocodeId: 0,
      newPromocode: {
        codename: '',
        bonus_sum: 0,
        description: '',
        is_work_after_first_pay: 0,
        start_dt: '',
        end_dt: ''
      },

      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },

      title: this.$t('menuitems.management.list.promo_codes'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.promo_codes'),
          active: true
        }
      ],
    };
  },
  validations: {
    newGroup: {
      codename: { required, minLength: minLength(1), maxLength: maxLength(40) },
      description: { required, minLength: minLength(1), maxLength: maxLength(255) },
    },
    newPromocode: {
      codename: { required, minLength: minLength(1), maxLength: maxLength(40) },
      bonus_sum: { required, minValue: minValue(1), maxValue: maxValue(50000) },
      description: { required, minLength: minLength(1), maxLength: maxLength(255) },
      start_dt: { required },
      end_dt: { required }
    },
  },
  computed: {

  },
  watch: {
    selectedGroup: function(val){
      if(val > 0){
        this.getPromocodes(val);
      }
    }
  },
  methods: {
    showDeleteGroupPopup(){
      this.deleteGroupPopup.show = true;
    },
    closeDeleteGroupPopup(){
      this.deleteGroupPopup.show = false;
    },
    deleteGroupPopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.selectedGroup);

      axios
          .post(`/v1/localization/delete-group-promocode`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeleteGroupPopup();
            this.selectedGroup = 0;
            this.promoCodes = [];
            this.getGroups();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    showAddGroupPopup(){
      this.addGroupPopup.show = true;
    },
    closeAddGroupPopup(){
      this.addGroupPopup.show = false;
    },
    showDeletePromocodePopup(promocode_id){
      this.deletedPromocodeId = promocode_id;
      this.deletePromocodePopup.show = true;
    },
    closeDeletePromocodePopup(){
      this.deletePromocodePopup.show = false;
    },
    deletePromocodePopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.deletedPromocodeId);

      axios
          .post(`/v1/localization/delete-promocode`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeletePromocodePopup();
            this.getPromocodes(this.selectedGroup);
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addGroupPopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newGroup.$touch();
      if(!this.$v.newGroup.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append('codename', this.newGroup.codename);
        formData.append('description', this.newGroup.description);

        axios
            .post(`/v1/localization/set-group-promocode`, formData)
            .then(() => {
              this.$store.dispatch("stopPreloader");
              this.closeAddGroupPopup();
              this.newGroup.codename = '';
              this.newGroup.description = '';
              this.getGroups();
              this.submitted = false;
            })
            .catch(err => {
              this.submitted = false;
              this.$store.dispatch("stopPreloader");
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            }); 
      }
    },

    showAddPromocodePopup(){
      this.addPromocodePopup.show = true;
    },
    closeAddPromocodePopup(){
      this.addPromocodePopup.show = false;
    },
    addPromocodePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPromocode.$touch();
      if(!this.$v.newPromocode.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append('promo_codes_group_id', this.selectedGroup);
        formData.append('codename', this.newPromocode.codename);
        formData.append('bonus_sum', this.newPromocode.bonus_sum);
        formData.append('description', this.newPromocode.description);
        formData.append('is_work_after_first_pay', this.newPromocode.is_work_after_first_pay);
        formData.append('start_dt', formatter.jsObjectToPhp(this.newPromocode.start_dt));
        formData.append('end_dt', formatter.jsObjectToPhp(this.newPromocode.end_dt));

        axios
            .post(`/v1/localization/set-promocode`, formData)
            .then(() => {
              this.$store.dispatch("stopPreloader");
              this.closeAddPromocodePopup();
              this.newPromocode.codename = '';
              this.newPromocode.bonus_sum = 0;
              this.newPromocode.description = '';
              this.newPromocode.is_work_after_first_pay = 0;
              this.newPromocode.start_dt = '';
              this.newPromocode.end_dt = '';
              this.getPromocodes(this.selectedGroup);
              this.submitted = false;
            })
            .catch(err => {
              this.submitted = false;
              this.$store.dispatch("stopPreloader");
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            }); 
      }
    },
    getPromocodes(group_id){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('group_id', group_id);

      axios
          .post(`/v1/localization/get-promocodes`, formData)
          .then(resp => {
            this.$store.dispatch("stopPreloader");
            this.promoCodes = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getGroups(){
      this.$store.dispatch("startPreloader");
      axios
          .get(`/v1/localization/get-group-promocodes`)
          .then(resp => {
            this.$store.dispatch("stopPreloader");
            this.groups = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    phpDatetimeToString(datetime){
      return formatter.phpDateTimeToShortString(datetime);
    }
  },
  created() {
    this.getGroups();
  }
};
